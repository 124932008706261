// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyDSyb06xgqNoWQ1hyo_U9GfOTr0ehcLabg",
  authDomain: "daiyans-auto-repair.firebaseapp.com",
  projectId: "daiyans-auto-repair",
  storageBucket: "daiyans-auto-repair.appspot.com",
  messagingSenderId: "997103363775",
  appId: "1:997103363775:web:178253c115d69a2c15480a",
};
